import React from 'react';
import ContactRow from './ContactRow';

function ContactTable({ contacts, onDeleteContact, onEditContact }) {
    return (
        <table className={"w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-4000"}>
            <thead className={"text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
            <tr>
                <th scope="col" className="px-6 py-3">Nom</th>
                <th scope="col" className="px-6 py-3">Prénom</th>
                <th scope="col" className="px-6 py-3">N° de tel</th>
                <th scope="col" className="px-6 py-3">Mail</th>
                <th scope="col" className="px-6 py-3">Adresse</th>
                <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
            </thead>
            <tbody>
            {contacts.map(contact => (
                <ContactRow key={contact.id} contact={contact} onDeleteContact={onDeleteContact} onEditContact={onEditContact} />
            ))}
            </tbody>
        </table>
    );
}

export default ContactTable;
