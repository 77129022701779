import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContactTable from './components/ContactTable';
import AddContactForm from './components/AddContactForm';
import PasswordForm from './components/PasswordForm';
import EditContactForm from './components/EditContactForm';
import Cookies from 'js-cookie';

function App() {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Ajout de l'état pour la recherche
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const apiUrl = "https://api-steve2024.robinbridoux.com";

  const updateContact = (id, updatedContact) => {
    axios.put(`${apiUrl}/contacts/${id}`, updatedContact)
      .then(res => {
        setContacts(contacts.map(contact => contact._id === id ? res.data : contact));
      })
      .catch(err => console.log(err));
  };

  const deleteContact = (id) => {
    axios.delete(`${apiUrl}/contacts/${id}`)
      .then(() => {
        setContacts(contacts.filter(contact => contact._id !== id));
      })
      .catch(err => console.log(err));
  };

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
  };

  const handleUpdateContact = (id, updatedContact) => {
    updateContact(id, updatedContact);
    setSelectedContact(null);
  };

  const handlePasswordVerified = () => {
    setIsAuthenticated(true);
  };

  const isAuthenticatedCookie = Cookies.get('authenticated');

  useEffect(() => {
    if (isAuthenticatedCookie) {
      setIsAuthenticated(true);
    }
  }, [isAuthenticatedCookie]);

  useEffect(() => {
    axios.get(`${apiUrl}/contacts`)
      .then(res => setContacts(res.data))
      .catch(err => console.log(err));
  }, []);

  if (!isAuthenticated || !Cookies.get('authenticated')) {
    return <PasswordForm onPasswordVerified={handlePasswordVerified} />;
  }

  const filteredContacts = contacts.filter(contact =>
    contact.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.prenom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.tel.includes(searchTerm) ||
    contact.mail.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.adresse.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addContact = (newContact) => {
    axios.post(`${apiUrl}/contacts`, newContact)
        .then(res => {
          setContacts([...contacts, newContact]);
        })
        .catch(err => console.log(err));
  };

  return (
      <div className="App flex flex-col gap-5 justify-center items-center p-10">
        <div className={"max-w-screen-xl md:max-w-screen-md lg:max-w-screen-lg sm:max-w-screen-sm flex flex-col gap-5"}>
            <span className={"text-4xl font-extrabold dark:text-white"}>Liste des contacts</span>
            <span className={"text-2xl font-bold dark:text-white"}>Ajouter un contact</span>
            <AddContactForm onAddContact={addContact} />
            <div className="pb-4 bg-white dark:bg-gray-900">
                <label htmlFor="table-search" className="sr-only">Recherche</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="text" id="table-search" className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Recherche..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
            </div>
            {selectedContact && <>
                <div className="fixed inset-0 z-40 bg-black opacity-50"></div> {/* Overlay */}
                <div id="modal" tabindex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex items-center justify-center">
                  <div className="relative p-4 w-full max-w-md max-h-full opacity-100">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                  Modifier un contact
                              </h3>
                              <button type="button" onClick={() => {setSelectedContact(null)}} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                  </svg>
                                  <span className="sr-only">Fermer</span>
                              </button>
                          </div>
                          <div class="p-4 md:p-5">
                            <EditContactForm contact={selectedContact} onUpdateContact={handleUpdateContact} />
                          </div>
                      </div>
                  </div>
              </div> 
            </>}
            <span className={"text-2xl font-bold dark:text-white"}>Tableau</span>
            <div className={"relative overflow-x-auto shadow-md sm:rounded-lg"}>
              <ContactTable contacts={filteredContacts} onDeleteContact={deleteContact} onEditContact={handleEditContact} />
            </div>
        </div>
      </div>
  );
}

export default App;
