import React from 'react';

function ContactRow({ contact, onDeleteContact, onEditContact }) {
    const { nom, prenom, tel, mail, adresse } = contact;

    function formatPhoneNumber(phoneNumber) {
        return phoneNumber.replace(/(\d{2})(?=\d)/g, '$1 ');
    }

    return (
        <tr className={"bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{nom}</td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{prenom}</td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><a href={`tel:${tel}`}>{formatPhoneNumber(tel)}</a></td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><a href={`mailto:${mail}`}>{mail}</a></td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{adresse}</td>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex gap-1">
                <div className='flex justify-center item-center w-10 h-10 p-2 object-contain rounded-lg border border-gray-200 text-red-600 bg-gray-50 hover:bg-gray-300 hover:cursor-pointer transition-all'>
                    <svg onClick={() => onDeleteContact(contact._id)} className="w-5 h-5  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                    </svg>
                </div>
                <div className='flex justify-center item-center w-10 h-10 p-2 object-contain rounded-lg border border-gray-200 bg-gray-50 hover:bg-gray-300 hover:cursor-pointer transition-all'>
                    <svg onClick={() => onEditContact(contact)} className="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"/>
                    </svg>
                </div>
            </td>
        </tr>
    );
}

export default ContactRow;